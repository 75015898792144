.Text_EclipsePlay {
  animation: color-change 11s infinite;
  margin-left: 10px;
  width: 150px;
  margin-top: 25px;
}

@keyframes color-change {
  0% {
    color: rgb(255, 153, 0);
  }
  50% {
    color: rgb(255, 251, 0);
  }
  100% {
    color: rgb(81, 255, 0);
  }
}
