.Score_ico {
  /* background-color: gold; */
  color: black;

  box-shadow: 0px 0px 10px 1px #ffffff inset;

  border-radius: 30px;
  font-size: 25px;
  width: 40px;
  height: 40px;
  text-align: center;
  align-self: center;
  font-weight: bolder;

  animation: color-change1 5s infinite;
}

@keyframes color-change1 {
  0% {
    background: linear-gradient(to right, #ffffff, #ffffff);
    box-shadow: 0px 0px 7px 1px hsla(0, 0%, 75%, 0.9);
    text-shadow: 0px 0px 3px hsla(0, 0%, 54%, 0.9);
    /* transform: rotate(0deg); */
  }
  50% {
    background: linear-gradient(to right, #ffffff, #dad8d8);
    box-shadow: 0px 0px 7px 1px hsla(0, 0%, 55%, 0.1);
    /* transform: rotate(180deg); */
  }
  100% {
    background: linear-gradient(to right, #ffffff, #ffffff);
    box-shadow: 0px 0px 7px 1px hsla(0, 0%, 76%, 0.9);
    text-shadow: 0px 0px 3px hsla(0, 0%, 51%, 0.9);
  }
}

.Coin_ico {
  color: black;

  box-shadow: 0px 0px 10px 1px #fffb0a inset;

  border-radius: 30px;
  font-size: 25px;
  width: 40px;
  height: 40px;
  text-align: center;
  align-self: center;
  font-weight: bolder;

  animation: color-change2 5s infinite;
}

.Coin_ico_small {
  color: black;

  box-shadow: 0px 0px 10px 1px #fffb0a inset;

  border-radius: 30px;
  font-size: 15px;
  width: 25px;
  height: 25px;
  text-align: center;
  align-self: center;
  font-weight: bolder;

  animation: color-change2 5s infinite;
}

@keyframes color-change2 {
  0% {
    background: linear-gradient(to right, #fdec00, #ffe600);
    box-shadow: 0px 0px 7px 1px hsla(59, 100%, 52%, 0.9);
    text-shadow: 0px 0px 3px hsla(59, 100%, 17%, 0.9);
    /* transform: rotate(0deg); */
  }
  50% {
    background: linear-gradient(to right, #fdec00, #ffd000);
    box-shadow: 0px 0px 7px 1px hsla(59, 100%, 52%, 0.1);
    /* transform: rotate(180deg); */
  }
  100% {
    background: linear-gradient(to right, #fdec00, #ffe600);
    box-shadow: 0px 0px 7px 1px hsla(59, 100%, 52%, 0.9);
    text-shadow: 0px 0px 3px hsla(59, 100%, 17%, 0.9);
    /* transform: rotate(360deg); */
  }
}
