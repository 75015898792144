.cart_flexbox {
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
  gap: 5px;
  margin-top: 50px;
}

.cart_flexbox_child {
  /* background: rgba(255, 255, 255, 0.5); */
  background-image: linear-gradient(
    to bottom right,
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.1)
  );
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.2);
  text-shadow: 0px 0px 3px hsla(0, 0%, 51%, 0.9);
  color: #ffffff;
  backdrop-filter: blur(11px);
  width: 200px;
  height: 290px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
}
.cart_flexbox_child:hover {
  animation-name: changecolor1;
  animation-duration: 0.2s;
  animation-fill-mode: forwards;

  color: #000000;
  cursor: pointer;
}
@keyframes changecolor1 {
  from {
    background: rgba(255, 255, 255, 0.3);
    box-shadow: 0px 0px 10px 0px rgba(255, 255, 255, 0);
  }
  to {
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 0px 10px 0px rgba(255, 255, 255, 0.5);
  }
}

.cart_flexbox_child:hover > .cart_item_bottom {
  color: #000000;
}
.cart_flexbox_child:hover > .div_arrange_Horizontal > .cart_item_priceworth {
  color: #000000;
}
.cart_flexbox_child:hover > .div_arrange_Horizontal > .cart_item_itemqtybal {
  color: #000000;
}

.cart_flexbox_child_img {
  width: auto;
  height: auto;
  /* background-color: aqua; */
}
.cart_flexbox_child_img_small {
  /* width: 20px; */
  height: 50px;
  /* background-color: aqua; */
}

.cart_item_top {
  position: absolute;
  /* background: linear-gradient(to right, #252525, #000000); */
}

.cart_item_bottom {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  /* background: linear-gradient(to right, #252525, #000000); */
  /* position: absolute; */
  height: 60px;
  text-align: center;
  color: #ffffff;
  font-size: 12px;
  margin-left: 5px;
  margin-right: 5px;
  /* border-bottom-left-radius: 5px; */
  /* border-bottom-right-radius: 5px; */
}

.cart_item_priceworth {
  color: #ffffff;
  font-size: 10px;
  text-align: left;
  /* margin-top: -25px; */
  font-weight: 300;
  width: 80%;
}
.cart_item_itemqtybal {
  color: #ffffff;
  font-size: 10px;
  text-align: left;
  /* margin-top: -25px; */
  font-weight: 300;
  width: 80%;
}

.cart_dialog {
  background: rgba(0, 0, 0, 0.5);
}

.cart_btn {
  background: none;

  color: #d0ff00;

  border-radius: 5px;
  padding: 5px 10px 5px 10px;
  right: 0;
  position: absolute;
  margin-right: 20px;
  cursor: pointer;
}
.cart_btn:hover {
  background: linear-gradient(to right, #eeff00, #d0ff00);
  box-shadow: 0px 0px 7px 1px hsla(76, 100%, 50%, 0.9);
  color: #000000;
}

.cart_btn:hover > .cart_item_bottom {
  color: #d0ff00;
}

.normal_btn {
  background: none;

  color: #000000;

  border-radius: 5px;
  padding: 5px 10px 5px 10px;
  /* right: 0;
  position: absolute;
  margin-right: 20px; */
  cursor: pointer;
}
.normal_btn:hover {
  background: linear-gradient(to right, #eeff00, #d0ff00);
  box-shadow: 0px 0px 7px 1px hsla(76, 100%, 50%, 0.9);
  color: #000000;
}
.normal_btn:hover > .cart_item_bottom {
  color: #d0ff00;
}

.alert_btn {
  background: none;

  color: #eb0000;

  border-radius: 5px;

  padding: 5px 10px 5px 10px;
  /* right: 0;
  position: absolute;
  margin-right: 20px; */
  cursor: pointer;
}
.alert_btn:hover {
  background: linear-gradient(to right, #f31000, #b40606);
  box-shadow: 0px 0px 7px 1px hsla(0, 100%, 50%, 0.9);
  color: #ffffff;
}
.alert_btn:hover > .cart_item_bottom {
  color: #ffffff;
}
