.div_arrange_Horizontal {
  display: flex;
  flex-direction: row;
  text-align: center;
  margin: 10px;
  gap: 5px;
  align-items: center;
}

.eclipsePlay_text {
  color: red;
  padding: 0 0 0 5px;
  font-weight: bolder;
}

.container_simple {
  background-color: #dfdfdf;
  border-radius: 5px;
  padding: 5px;
}

.div_maximumWidth {
  max-width: 1920px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
