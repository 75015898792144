.NavBar_bg {
  background-image: linear-gradient(
    to bottom right,
    rgba(0, 174, 239, 0.8),
    rgba(0, 174, 239, 1)
  );
  box-shadow: 0 8px 32px 0 rgba(0, 174, 239, 0.37);
  backdrop-filter: blur(7px);

  width: 100%;
  color: black;

  margin-top: -5px;

  display: flex;
  flex-direction: row;
  gap: 5px;

  position: fixed;
  z-index: 33;
}
.NavBar_child {
  /* background-color: aquamarine; */
  height: 80px;
  padding: 20px;
  cursor: pointer;
}
.NavBar_child:hover {
  background-image: linear-gradient(
    to bottom,
    rgba(0, 174, 239, 1) 95%,
    rgba(185, 236, 255, 1) 10%
  );
  box-shadow: 0 0 50px 1px rgba(0, 174, 239, 1);
}

.NavBar_Response {
  margin-top: 80px;
}
