.bg {
  background-color: rgba(27, 27, 27, 0.5);
  /* padding: 0px 0px px 5px; */
  /* border-radius: 10px; */
  display: flex;
  font-size: 10px;
  color: #ffffff;
}

.userProfileImgContainer {
  text-align: center;
  border-radius: 5px;
  background-color: rgb(27, 27, 27);
}

.userProfileImg {
  border-radius: 50%;
  height: 45px;
  margin-top: 5px;
}

.userProfileOInfoContainer {
  padding: 0px 0px 0px 5px;
  font-size: 20px;
  white-space: nowrap;
}

.userProfileLogoutBtn {
  /* color: #fc0303; */
  text-transform: none;
  font-size: 12px;
  padding: 1px;
  font-weight: bolder;
}
.userProfileLogoutBtn:hover {
  color: #ffffff;
  background-color: #fc0303;
  cursor: pointer;
  border-radius: 30px;
}

.div_alignToRight {
  flex-direction: row;
  position: absolute;
  right: 0;
}
